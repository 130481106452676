
import scannerTotem   from "@src/assets/images/scanner-totem.png";
import scannerCashier from "@src/assets/images/scanner-cashier.png";

import Modal from "@src/libs/ui/Modal";

import ConfigStore from "@src/model/config";

import { observer, useLocalObservable } from "mobx-react";
import React from "react";
import { useEffect } from "react";
import { enableQR } from "@src/services/Totem";


const ModalScanTotem: React.FC = observer(() => {
  
  const meta = useLocalObservable(() => ({
    visibleSugest: false,
    focus: "",
  }));

  useEffect(()=>{
    if(ConfigStore.showModalBarcode==2){
      ConfigStore.isWaitingQR=true
      enableQR()
    }
  },[ConfigStore.showModalBarcode])

  // receiveQR
  const cancel = () => {
    ConfigStore.showModalBarcode=0
    ConfigStore.isWaitingQR=false
    enableQR()
  };
  
  return (
    <Modal visible={ConfigStore.showModalBarcode==2} onDismiss={cancel}
    className="h-64">
      <div className="bg-white p-4 h-full rounded-md">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex flex-1 flex-col">
            <h1
              className="text-xl font-bold leading-6 text-gray-800 mb-3"
              id="modal-headline"
            >
              Pilih Barcode Scanner
            </h1>
            <div className="mb-2">
              
              <div className="flex flex-row"
              
              >
                {/* {OrderMethod.map((item, idx) => {
                  
                  return ( */}
                    <div className="mb-2 mr-5 flex flex-1 flex-col"
                      
                    >
                      <button
                        className={`opacity-75 hover:opacity-100 border-2 rounded-lg overflow-hidden relative align-middle`}
                        style={{
                            borderColor:"#1ACBDA",
                            backgroundColor:"#1ACBDA33",
                        }}
                        
                      >
                        
                        <div
                         //className={`h-24 w-full flex-1 overflow-hidden bg-contain bg-center bg-no-repeat align-middle`}
                          style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: "9rem"}}
                        >
                            <div 
                            className={`h-24 w-full flex-none overflow-hidden bg-contain bg-center bg-no-repeat`}
                            style={{
                              backgroundImage: `url(${scannerTotem})`,
                              }}>
                                
                            </div>
                            
                            
                            
                        </div>
                      </button>
                      <text className="text-base text-center">Barcode Scanner {"\n"}(Totem)</text>
                    </div>
                  {/* );
                })} */}
                    <div className="flex flex-col"> 
                        <div className="font-bold text-xl "
                        style={{
                            color:"#1ACBDA"
                        }}>Connected to {ConfigStore.selectedTotem.name}</div>
                        <div className="font-bold mt-6">Menunggu proses scan</div>
                    </div>
              </div>

             

            </div>
            
          </div>
        </div>
      </div>
      
    </Modal>
  );
});



export default ModalScanTotem;
