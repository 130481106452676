import { dateFormat, moneyFormat } from "@src/libs/utils/stringFormat";
import OrderStore from "@src/model/order-repo";
import printStruk from "@src/services/PrintStruk";
import { Cash, PlusOutline, PrinterOutline, X, CheckCircle } from "heroicons-react";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import DataIsEmpty from "../DataIsEmpty";
import { Plugins } from "@capacitor/core";
import { useEffect } from "react";
import ConfigStore from "@src/model/config";
import {ic_room_service} from 'react-icons-kit/md/ic_room_service'
import Icon from "react-icons-kit";
const { Device } = Plugins;

export default observer(() => {
  // let isWeb=false

  // Device.getInfo().then((it)=>{
  //   isWeb = (it.platform === "web")
  // })

  // useEffect(()=>{
  //   runInAction(()=>{
  //     ConfigStore.isWeb=isWeb
  //   })
    
  // },[isWeb])

  if (!OrderStore.idDetailOrder)
    return (
      <div className="col-span-8 lg:col-span-9 p-2 overflow-hidden flex flex-col">
        <DataIsEmpty
          description={
            "Pilih item penjualan pada sisi kiri untuk melihat rincian pesanan."
          }
        />
      </div>
    );

  const order = OrderStore.getDetailOrder;

  return (
    <div className="col-span-8 lg:col-span-9 p-2 overflow-hidden flex flex-col">
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="p-2 bg-white rounded-md flex flex-row justify-between">
          <div>
            <div className="font-bold text-xl">
              {order.additional_customer_name}
            </div>
            <div>
              <div className="text-xs">No. Order: </div>
              <div className="text-sm font-bold ml-4">
                {order.sales_order_number}
              </div>
            </div>
            {!!order.sales_order_date && (
              <div>
                <div className="text-xs">Tanggal Order: </div>
                <div className="text-sm font-bold ml-4">
                  {dateFormat(order.sales_order_date, "d MMMM yyyy")}
                </div>
              </div>
            )}
            {order.src === "CRP" && order.deliver_date && (
              <div>
                <div className="text-xs">Tanggal Pengiriman: </div>
                <div className="text-sm font-bold ml-4">
                  {dateFormat(order.deliver_date, "d MMMM yyyy - HH:mm:ss")}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col items-end">
            {order.so_status === "paid" && (
              <>
                <div>
                  {/* <div className="inline-flex mb-2 justify-center rounded-md border border-transparent pb-3 text-lg leading-6 font-medium text-black sm:leading-5 ripple items-center">
                    {!!OrderStore.getDetailOrder.queue && "#"+OrderStore.getDetailOrder.queue}
                  </div> */}
                  
                  
                  {/* {
                    ConfigStore.isWeb && ( */}
                      <button
                      type="button"
                      className="inline-flex mb-2 justify-center rounded-md border border-transparent p-2 text-lg leading-6 font-medium text-blue-400 hover:bg-blue-100 focus:outline-none focus:border-blue-700 transition ease-in-out duration-150 sm:leading-5 ripple items-center"
                      onClick={(e) => {
                        OrderStore.getDetailOrderLabel()
                        OrderStore.showLabel = true;
                        
                      }}
                    >
                      <PrinterOutline size={25} className="text-blue-400" />
                      <span className="ml-4">Label</span>
                    </button>
                    {/* )
                  } */}
                 
                  <button
                    type="button"
                    className="inline-flex mb-2 justify-center rounded-md border border-transparent p-2 text-lg leading-6 font-medium text-blue-400 hover:bg-blue-100 focus:outline-none focus:border-blue-700 transition ease-in-out duration-150 sm:leading-5 ripple items-center"
                    onClick={(e) => {
                      printStruk(order);
                    }}
                  >
                    <PrinterOutline size={25} className="text-blue-400" />
                    <span className="ml-4">Struk</span>
                  </button>
                </div>
              </>
            )}
            <div className="flex flex-row ">

            {order.payment_method!=="" && order.payment_method!=="null" &&
              <div className="font-bold capitalize bg-blue-100 text-blue-700 border border-blue-300 py-2 px-4 mr-4 rounded-md text-center">
              {order.payment_method}
              </div>
              }
            
              {order.queue!=="" && order.queue!=="null" &&
              <div className="font-bold capitalize bg-blue-100 text-blue-700 border border-blue-300 py-2 px-4 mr-4 rounded-md text-center">
              {"No. Antrian: "}{order.queue}
              </div>
              }
              <div className="font-bold capitalize bg-blue-100 text-blue-700 border border-blue-300 py-2 px-4 rounded-md text-center">
                {order.so_status}
              </div>
            </div>
            {"\n"}
            <div className="text-md mt-2">{order.so_status==="cancelled" && !!order.notes && order.notes!=="null" && order.notes }</div>
            
          </div>
        </div>
        <div className="overflow-hidden flex flex-1 flex-col rounded-md bg-white my-2">
          <div className="flex flex-1 overflow-auto">
            <div className="w-full">

              <table className="w-full overflow-hidden">
              <thead>
                <tr className="bg-gray-200 divide-x border-gray-400">
                  <th className="py-1">No.</th>
                  <th className="text-left px-2">Nama Produk</th>
                  <th className="text-left px-2 w-40">Status</th>
                  <th className="text-right px-2 w-40">Total</th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {OrderStore.getDetailOrder.items.map((item, index) => (
                  <tr key={index} className="bg-white h-auto hover:bg-gray-100">
                    <td className="text-center px-2 py-2 w-16">{index + 1}.</td>
                    <td className="text-left px-2 py-2">
                      <div>
                        <div className="font-bold">{item.product_name}</div>
                        <div className="text-sm">{item.complement_str}</div>
                        <div className="text-sm">
                          <span className="font-bold">{item.qty}x</span>{" "}
                          {moneyFormat(item.price, "Rp. ")}
                        </div>
                      </div>
                    </td>
                    <td className="text-left px-2 py-2">
                      {item.status || "-"}
                    </td>
                    <td className="text-right px-2 py-2">
                      {moneyFormat(item.sub_total, "Rp. ")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
          {order.so_status === "submitted" && (
            <span className="flex w-full rounded-md shadow-sm m-2 sm:w-auto">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent p-2 text-lg leading-6 font-medium text-white shadow-sm bg-blue-300 hover:bg-blue-500 hover:text-white focus:outline-none focus:border-blue-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple items-center"
                onClick={(e) => {
                  OrderStore.setUpdateOrder(order);
                  runInAction(() => {
                    OrderStore.openProductPicker = true;
                  });
                }}
              >
                <PlusOutline size={30} />
                <span>Tambah Pesanan</span>
              </button>
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-4 w-full">
        <div className="flex flex-row px-4 rounded-md w-1/2 gap-4">
          {["complete", "paid", "submitted"].indexOf(order.so_status) > -1 && (
            <span className="flex w-1/2">
              <button
                type="button"
                className={`inline-flex flex-1 justify-center rounded-md border-transparent p-2 text-lg leading-6 font-medium text-white shadow-sm bg-gray-100 border-gray-800 border-2 hover:text-white focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple items-center hover:shadow-md ${
                  order.canCancel ? "hover:bg-gray-300" : "hover:bg-red-600"
                }`}
                onClick={(e) => {
                  OrderStore.setUpdateOrder(order);
                  OrderStore.confirmCancel();
                }}
              >
                <X size={50} color="#2d3748"/>
                <span className="ml-2 text-gray-800">Batalkan Pesanan</span>
              </button>
            </span>
          )}
          {order.so_status === "submitted" && (
            <span className="flex w-1/2">
              <button
                type="button"
                className="inline-flex flex-1 justify-center rounded-md border border-transparent p-2 text-lg leading-6 font-medium text-white shadow-sm bg-blue-400 hover:bg-blue-500 hover:text-white focus:outline-none focus:border-blue-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple items-center"
                onClick={(e) => {
                  OrderStore.setUpdateOrder(order);
                  runInAction(() => {
                    OrderStore.openPaymentSubmit = true;
                  });
                }}
              >
                <Cash size={50} />
                <span className="ml-2">Bayar Pesanan</span>
              </button>
            </span>
          )}
          {(order.q_status === 1 || order.queue==="null" || ConfigStore.isQueue===false )&& order.so_status === "paid" && (
            <span className="flex w-1/2">
              <button
                type="button"
                className="inline-flex flex-1 justify-center rounded-md border border-transparent p-2 text-lg leading-6 font-medium text-white shadow-sm bg-blue-700 hover:bg-blue-500 hover:text-white focus:outline-none focus:border-blue-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple items-center"
                
                onClick={(e) => {
                  OrderStore.setUpdateOrder(order);
                  OrderStore.complete();
                }}
              >
                <CheckCircle size={50} />
                <span className="ml-2">Pesanan Selesai</span>
              </button>
            </span>
          )}
          {order.queue!=="null" && order.q_status === 0 && order.so_status === "paid" && ConfigStore.isQueue===true && (
            <span className="flex w-1/2">
              <button
                type="button"
                className="inline-flex flex-1 justify-center rounded-md border border-transparent p-2 text-lg leading-6 font-medium text-white shadow-sm bg-green-600 hover:bg-green-400 hover:text-white focus:outline-none focus:border-blue-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple items-center"
                onClick={(e) => {
                  // OrderStore.updateQueue(order);
                  OrderStore.setUpdateOrder(order);
                  OrderStore.serve();
                }}
              >
                
                <Icon
                  icon={ic_room_service}
                  size={50}
                  style={{
                    color: "#fff",
                  }}
                />
                <span className="ml-2">Siap Diambil</span>
              </button>
            </span>
          )}
        </div>
        <div className="flex flex-col bg-white p-2 rounded-md w-1/2 shadow-md">
          <div className="flex flex-row justify-between mb-1">
            <h1 className=" text-sm">Subtotal</h1>
            <h1 className=" text-sm">{moneyFormat(order.sub_total, "Rp. ")}</h1>
          </div>
          <div className="flex flex-row justify-between mb-1">
            <h1 className=" text-sm">Diskon</h1>
            <h1 className=" text-sm">{moneyFormat(order.amount_discount, "Rp. ")}</h1>
          </div>
          <div className="flex flex-row justify-between">
            <h1 className="font-bold text-xl">Total</h1>
            <h1 className="font-bold text-xl">
              {moneyFormat(order.grand_total, "Rp. ")}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
});
