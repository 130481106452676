import {
  FilesystemDirectory,
  FilesystemEncoding,
  Plugins,
} from "@capacitor/core";
import { navigate } from "@reach/router";
import SessionApi from "@src/api/SessionApi";
import SessionMobileApi from "@src/api/SessionMobileApi";
import { AppConfig } from "@src/config/app";
import { Model } from "@src/libs/model/model";
import { initTotem } from "@src/services/Totem";
import { runInAction } from "mobx";
import CustomerAPI from "../api/CustomerApi";

const { Filesystem } = Plugins;

export class Company extends Model {
  client_name = "";
  outlet_name = "";
  alamat = "";
  telpon = "";
  kota = "";
  logo_url = "";
  invoice_add_text = "";
}
export class LoginForm extends Model {
  username = "";
  password = "";
  loading = false;
}

export class SetupForm extends Model {
  server_url: string = "";
  app_name: string = "";
  app_token: string = "";
  id_client: number = 0;
  id_outlet: number = 0;
}

export class Totem extends Model {
  id = 0;
  name = "";
  outlet = 0;
  room = "";
  socket_id = "";
  type = "";
  
}

export class ConfigRepository extends Model {
  id: string = "";
  server_url: string = "";
  sess_id: string = "";
  app_name: string = "";
  app_token: string = "";
  user_token: string = "";
  id_client: number = 0;
  id_outlet: number = 0;
  user_id: number = 0;
  username="";
  fullname="";

  role = "";
  selectedPrinter = "";
  
  selectedStrukPrinters="";
  selectedLabelPrinters="";

  selectedStrukName="";
  selectedLabelName="";

  labelMarginLeft=2;
  labelMaxChar=28;
  labelLineCount=3;

  strukEnableLogo=true;
  

  selectedTotem = Totem.childOf(this);

  tempRoom =""; //indicator
  tempTotem =Totem.childOf(this) // Temporary selected totem

  showListTotem = false
  selectedPaper = 58;
  socket:any

  isLoggedIn = false;

  statusCamera = "hidden";

  form = LoginForm.childOf(this);
  setup = SetupForm.childOf(this);
  company = Company.childOf(this);
  totems = Totem.hasMany(this);
  socketIsConnected=0
  isWeb=false

  showModalBarcode = 0;
  isWaitingQR = false;

  isQueue = false

  loading = false;

  updateChecked(x: number) {
    if (this.totems[x].room+this.totems[x].name === this.tempRoom) {
      this.tempRoom = "";
      this.tempTotem= new Totem();
    } else {
      this.tempRoom =this.totems[x].room+this.totems[x].name;
      this.tempTotem= this.totems[x];
    }
  }

  get is_admin() {
    return this.role === "admin";
  }

  get is_sales() {
    return this.role === "sales";
  }

  public async checkSession(token: string) {
    runInAction(()=>{
      this.server_url=AppConfig.server
      this.isWeb =true
    })
    
    const res = await SessionApi.checkToken(token);
    
    //console.log(JSON.stringify(res))
    this._loadJSON(res);
    runInAction(()=>{
      this._loadJSON(res);
    })
    
    const queue = await SessionApi.getAddOnQueue();
    runInAction(()=>{
      this.isQueue = queue
      
    })

    
    if (Object.keys(res).length > 0) {
      this.form._loadJSON({
        username: res.username,
      });
      this.loadPrintHeader();
      return true;
    } else {
      let a = new ConfigRepository();
      this._loadJSON(a._json);
      return false;
    }

    
  }

  loadSetup() {
    this.setup._loadJSON(this._json);
  }

  async loadPrintHeader() {
    const res = await CustomerAPI.getCompany();
    this.company._loadJSON(res);
  }

  
  async login() {
    this.form.loading = true;
    this.username = this.form.username;
    this.server_url=AppConfig.server
    try {
      const res = await SessionMobileApi.login(
        this.server_url,
        this.app_name,
        this.app_token,
        this.form._json
      ).catch(() => {
        this.form._loadJSON({
          loading: false,
        });
      });
      setTimeout(() => {
        if (this.form.loading) {
          this.form._loadJSON({
            loading: false,
          });
        }
      }, 10000);
      if (!!res.user_token) {
        this.fullname = res.user.fullname
        
        let is_admin =
          res.user.roles.findIndex(
            (x: any) =>
              x.menu_path.indexOf("admin") > -1
              //  ||
              // x.menu_path.indexOf("corp_supervisor") > -1
          ) > -1;
        let is_sales =
          res.user.roles.findIndex(
            (x: any) => x.menu_path.indexOf("sales") > -1
            ||
            x.menu_path.indexOf("corp_supervisor") > -1
          ) > -1;

        this.form._loadJSON({
          loading: false,
        });
        if (!is_admin && !is_sales) {
          alert("Akun tidak bisa digunakan di aplikasi ini.");
        } else {
          this._loadJSON({
            ...res.user,
            user_token: res.user_token,
            
            id_client: res.client.id,
            role: is_admin ? "admin" : "sales",
          });
          
          this.loadPrintHeader();
          this.saveSetup(this._json);
          if (is_admin) {
            navigate("/setup", { replace: true });
          } else {
            runInAction(()=>{
              ConfigStore.socketIsConnected=0
            })

            const queue = await SessionApi.getAddOnQueue();
            runInAction(()=>{
              this.isQueue = queue
            })
            
            initTotem();
            navigate("/order-list", { replace: true });
          }
        }
      }
    } catch (error) {
      this.form._loadJSON({
        loading: false,
      });
    }
    this.form.loading =false;
  }

  checkConfigStore(){
    if(this.company.client_name==="" || this.company.client_name===null || this.company.client_name===undefined){
      navigate("login", { replace: true });
    }
    if(ConfigStore.user_token==="" || ConfigStore.user_token===null || ConfigStore.user_token===undefined){
      navigate("login", { replace: true });
    }
  }

  async logout() {
    this._loadJSON({
      user_token: "",
      id_client: 0,
      is_admin: false,
      form: {
        username: "",
        password: "",
        loading: false,
      },
    });
    
    ConfigStore.user_token="";
    
    navigate("login", { replace: true });
    ConfigStore.user_token="";
    try {
      await Filesystem.deleteFile({
        path: "setting.json",
        directory: FilesystemDirectory.Data,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async saveSetup(data: object) {
    try {
      await Filesystem.writeFile({
        path: "setting.json",
        data: JSON.stringify(data),
        directory: FilesystemDirectory.Data,
        encoding: FilesystemEncoding.UTF8,
      });
      this._loadJSON({
        ...data,
        form: {
          username: "",
          password: "",
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
}

const ConfigStore = ConfigRepository.create({
  localStorage: true,
  storageName: "ConfigRepository",
  encrypt: true,
});
export default ConfigStore;
