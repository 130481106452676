import ConfigStore, { Totem } from "@src/model/config";
import OrderStore from "@src/model/order-repo";
import { runInAction, toJS } from "mobx";
import React from "react";
const SocketIO = require("socket.io-client");

export const wsRef: any = React.createRef();

export const initTotem = () => {
  
  const socket = SocketIO("https://ttm.kelava.id", {
    secure: true,
    reconnection: true,
  });
  wsRef.current = socket;
  const ws = wsRef.current;

  ws.on("connect", function () {
    console.log("connected");
    runInAction(()=>{
      ConfigStore.socket = ws;
    })
    
    runInAction(()=>{
      ConfigStore.socketIsConnected=1
    })
    
    getTotem();

    if (ConfigStore.selectedTotem.room!="") {
      connectTotem()
    }
  });
  ws.on("connect_failed", function (e: any) {
    runInAction(()=>{
      ConfigStore.socketIsConnected=-1
    })
    
    console.log("error", e);
  });

  ws.on("receive-qr", function(data: any){
    console.log("Incoming data")
    //console.log((data))
    if(ConfigStore.showModalBarcode==2){
      console.log("POS receive: "+data.data)
      OrderStore.filter = data.data;
      OrderStore.filterDate = "";
      
      ConfigStore.showModalBarcode=0;
      OrderStore.idDetailOrder = OrderStore.getList[0].id

      ConfigStore.isWaitingQR=false
      enableQR()

    //   console.log(data.data)
      
    }
  });

  ws.on("receive-totem", function (data: any) {
    ConfigStore._loadJSON({
      totems: data.data,
    });
    
  });

  ws.on("disconnect", function () {
    
    runInAction(()=>{
      ConfigStore.socketIsConnected=-1
    })
    console.log("disconnected");
  });

  ws.on("log", function (data: any) {
    console.log("log");
    console.log(data);
  });

  
  ws.connect();
};



// export const receiveQR = () => {
//   console.log("Awaiting")
//   const ws = wsRef.current;
//   try{
//     if (ConfigStore.socket.disconnected && ws.disconnected) {
//       alert("Service disconected.");
//       onDisconnect()
//     }else{
//       ws.on("receive-qr", function (data: any) {
//         console.log("Incoming data")
//         if(ConfigStore.showModalBarcode==2){
//           // OrderStore.filter = data.data;
//           // OrderStore.filterDate = "";
//           console.log("POS receive: ")
//           console.log(data.data)
          
//         }
            
//       });
//     }
//   }catch{
//     alert("Service disconected.");
//     onDisconnect()
//   }
  
// };


export const getTotem = () => {
  const ws = wsRef.current;
  console.log("getTotem: "+!wsRef.current.disconnected)
  ws.emit("get-totems", {
    id: ConfigStore.id,
    outlet: ConfigStore.id_outlet,
  });
};

export const enableQR = () => {
  const ws = wsRef.current;
  
  try{
    ws.emit("enable-qr", {
      room: ConfigStore.selectedTotem.room,
      state: ConfigStore.isWaitingQR,
    });
  }catch{
    alert("Totem disconected.");
    ConfigStore.showModalBarcode=0
    ConfigStore.isWaitingQR=false
    onDisconnect()
    console.log("Service disconected.")
  }
};


export const leftRoom = () => {
  const ws = wsRef.current;
  
  try{
    ws.disconnect();

    initTotem()
  }catch{
    ConfigStore.showModalBarcode=0
    ConfigStore.isWaitingQR=false
    onDisconnect()
    console.log("Service disconected.")
  }
};

//socket.disconnect()

export const connectTotem = () => {
  const ws = wsRef.current;
  
  if (ConfigStore.socket.disconnected && ws.disconnected) {
    //alert("Service disconected.");
    console.log("Service disconected.")
    onDisconnect()
  } else {
    if (!ConfigStore.tempTotem.room) {
      alert("Please select active totem.");
    } else {
     // alert("Service conected.");
      //   data = {
    //     id: 527,
    //     room : 'T20210602162443',
    //     outlet : 8,
    //     username : 'Sales Test',
    //     type : 'totem'
    // }
   
      var data = {
        id: ConfigStore.id,
        room: ConfigStore.selectedTotem.room,
        outlet: ConfigStore.id_outlet,
        username: ConfigStore.username,
        type: "pos",
      };


     //alert(JSON.stringify(data))
    

      ws.emit('join', data); 
    }
  }
};

export const sendToTotem = (data: any) => {
  console.log(toJS(data));
  const ws = wsRef.current;
  try{
    if (ConfigStore.socket.disconnected && ws.disconnected) {
      alert("Service disconected.");
      onDisconnect()
      console.log("Service disconected.")
    } else {
      if (!ConfigStore.selectedTotem.id) {
        //alert("Please select active totem.");
      } else {
        ws.emit("send-list", {
          room: ConfigStore.selectedTotem.room,
          product_data: data,
        });
      }
    }
  }catch{
    //alert("Service disconected.");
    onDisconnect()
    console.log("Service disconected.")
  }
  
};

const onDisconnect = () => {
  runInAction(()=>{
    ConfigStore.socketIsConnected=0
  })
  
  ConfigStore.selectedTotem=new Totem()
  ConfigStore.totems=[]
};
// ws.emit('send-list', {
//   room : 'T20210602162443',
//   product_data : {
//     "productMember": {
//       "categories": [],
//       "subcategories": [],
//       "list": [],
//       "activeCategory": 0,
//       "filter": ""
//     },
//     "id": 1623144131480,
//     "method": "POS",
//     "name": "Guest",
//     "phone": "",
//     "sales_order_date": "2021-06-08",
//     "sales_order_number": "",
//     "ppn": 0,
//     "purchase_order_number": "",
//     "customer_code": "",
//     "id_customer": 0,
//     "id_promo": 0,
//     "items": [
//       {
//         "id": 1623144133971,
//         "id_product": 337,
//         "product_name": "Black Coffee",
//         "qty": 1,
//         "price": 12000,
//         "sub_total": 0,
//         "status": "",
//         "isDeleted": "N",
//         "complement": [
//           {
//             "id": 383,
//             "name": "Dingin",
//             "price": 3000,
//             "type": "Suhu",
//             "is_default": 1
//           },
//           {
//             "id": 387,
//             "name": "More Sugar",
//             "price": 2000,
//             "type": "Other",
//             "is_default": 0
//           }
//         ],
//         "mode": "add",
//         "checklabel": 0,
//         "tempId": 0
//       },
//       {
//         "id": 1623144360949,
//         "id_product": 335,
//         "product_name": "Milk Coffee",
//         "qty": 2,
//         "price": 15000,
//         "sub_total": 0,
//         "status": "",
//         "isDeleted": "N",
//         "complement": [
//           {
//             "id": 383,
//             "name": "Dingin",
//             "price": 3000,
//             "type": "Suhu",
//             "is_default": 1
//           }
//         ],
//         "mode": "update",
//         "checklabel": 0,
//         "tempId": 0
//       },
//       {
//         "id": 1623144371351,
//         "id_product": 340,
//         "product_name": "Bakso",
//         "qty": 1,
//         "price": 10000,
//         "sub_total": 0,
//         "status": "",
//         "isDeleted": "N",
//         "complement": [],
//         "mode": "add",
//         "checklabel": 0,
//         "tempId": 0
//       }
//     ],
//     "discount": 0,
//     "discount_amount": 0,
//     "payment_status": "NOW",
//     "payment_method": "",
//     "paid_method": "",
//     "remarks": "",
//     "status": "",
//     "is_new_customer": false,
//     "is_member": false,
//     "is_cancel": "N",
//     "address": "",
//     "email": ""
//   }
// })