import ProductStore from "@src/model/product-repo";
import { CategorySingle } from "@src/model/product-single";
import { observer } from "mobx-react";
import React from "react";

const ProductCategory = observer(({ currentOrder }: any) => {
  let listCategories = ProductStore.listCategories;

  if (currentOrder.productMember.list.length > 0) {
    listCategories = currentOrder.productMember.categories;
  }

  return (
    <div className="px-4 mb-2 flex flex-row items-start">
      <div className="font-bold mr-4 my-2">Category:</div>
      <div className="flex flex-1 w-full">
        <div className="flex flex-row flex-wrap">
          {Array.isArray(listCategories) && listCategories.length > 0 ? (
            listCategories.map((item) => (
              <Item
                key={String(item.id)}
                item={item}
                currentOrder={currentOrder}
              />
            ))
          ) : (
            <h1>-</h1>
          )}
        </div>
      </div>
    </div>
  );
});

const Item: React.FC<Record<string, any>> = observer((props) => {
  const item: CategorySingle = props.item;
  const currentOrder = props.currentOrder;
  let activeCategory = ProductStore.activeCategory;
  if (currentOrder.productMember.list.length > 0) {
    activeCategory = currentOrder.productMember.activeCategory;
  }
  const action = () => {
    if (currentOrder.productMember.list.length > 0) {
      currentOrder.productMember.setCategory(item.id);
    } else {
      ProductStore.setCategory(item.id);
    }
  };
  let activeStyle = "";
  if (item.id === activeCategory) {
    activeStyle = "hover:bg-blue-500 bg-blue-500 text-white";
  }

  return (
    <button
      onClick={action}
      className={`flex rounded-md py-2 px-6 mr-2 mb-1 bg-gray-200 hover:bg-blue-200 text-md ripple hover:shadow-md ${activeStyle}`}
    >
      {item.name}
    </button>
  );
});

export default ProductCategory;
