import { observer } from "mobx-react";
import React, { InputHTMLAttributes } from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";

export default observer((props: CurrencyInputProps) => {
  return (
    <div className={
      "border-solid border-gray-500 border rounded text-gray-800 p-1  text-right w-full"
    }>
      {props.type=="amount" && "Rp."}
      
      <CurrencyInput
            decimalsLimit={0}
            allowNegativeValue={false}
            {...props}
            style={{
              width:75,
              textAlign:"end"
            }}
          />
      {props.type=="percent" && "%"}
    </div>
    
  );
});
