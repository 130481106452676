import { Model } from "../libs/model/model";
import { PaymentMethodSingle } from "./paymentmethod-single";
import PaymentMethodApi from "../api/PaymentMethodApi";

export class PaymentMethodRepository extends Model {
  list: PaymentMethodSingle[] = PaymentMethodSingle.hasMany(this);

  public async reload() {
    await PaymentMethodApi.getList().then((res: any) =>
    this._loadJSON({
      list: res,
    })
  );
  }
}

const PaymentMethodStore = PaymentMethodRepository.create({
  localStorage: false,
  storageName: "PaymentMethodRepository",
});
export default PaymentMethodStore;
