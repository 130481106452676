import React, { HTMLAttributes } from "react";
import nodata from "@src/assets/images/undraw_no_data.png";
import { RefreshOutline } from "heroicons-react";

interface ITopBar {
  description: string;
  imageProps?: HTMLAttributes<HTMLImageElement>;
  onRefresh?: any;
}

const DataIsEmpty = (props: ITopBar) => {
  let { imageProps, description, onRefresh } = props;
  let cprops: any = { ...props };
  delete cprops.description;
  delete cprops.imageProps;
  delete cprops.onRefresh;

  return (
    <div
      className="w-full h-full flex flex-col justify-center items-center"
      {...cprops}
    >
      <img
        className="h-auto w-56 bg-center"
        src={nodata}
        alt="Kelava"
        {...imageProps}
      />
      <h1 className="text-gray-500 text-sm">{description}</h1>
      {typeof onRefresh === "function" && (
        <button
          className="ml-3 rounded flex justify-center items-center ripple text-blue-400 hover:text-blue-600"
          onClick={onRefresh}
        >
          <RefreshOutline size={20} />
          <span className="m-2">Refresh</span>
        </button>
      )}
    </div>
  );
};

export default DataIsEmpty;
