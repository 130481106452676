import api from "@src/libs/utils/api";

const login = async (
  serverUrl: string,
  appName: string,
  appToken: string,
  data: any
) => {
  const params: any = {
    method: "post",
    url: serverUrl + "index.php?r=apiAuth/LoginMobile",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      appName: appName,
      appToken: appToken,
      data,
    },
  };

  
  const res: any = await api(params);
  // alert(JSON.stringify(res.data))
  if (typeof res === "object") {
    if (!!res.status) {
      //alert(JSON.stringify(res));
      return res.data;
    } else {
      alert(res.message);
      return {};
    }
  } else {
    console.log(res);
  }
};

const SessionMobileApi = {
  login,
};

export default SessionMobileApi;
