import CheckBox from "@src/libs/ui/CheckBox";
import Modal from "@src/libs/ui/Modal";
import TextAreaInput from "@src/libs/ui/TextAreaInput";
import TextCurrencyInput from "@src/libs/ui/TextCurrencyInput";
import { moneyFormat } from "@src/libs/utils/stringFormat";
import ConfigStore from "@src/model/config";
import OrderStore from "@src/model/order-repo";
import PaymentMethodStore from "@src/model/paymentmethod-repo";
import { Check } from "heroicons-react";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import React from "react";

const ModalSubmitNew: React.FC = observer(() => {
  const currentOrder = OrderStore.updateOrder;
  if (!currentOrder) return null;

  const meta = useLocalObservable(() => ({
    focusedChange: false,
    focusedDiscount: false,
    focusedDiscountAmt: false,
  }));

  const submit = async () => {
    OrderStore.update(true);
  };

   const dismiss = () => {
    runInAction(() => (OrderStore.openPaymentSubmit = false));
  };


  return (
    <Modal visible={!!OrderStore.openPaymentSubmit} onDismiss={dismiss}>
      <div className="bg-white px-4 pt-4 overflow-auto pb-4">
        <div className="sm:flex sm:items-start">
          <div className="text-center sm:text-left flex flex-1 flex-row">
            <div className="flex-grow">
              

              {currentOrder.payment_status === "NOW" && (
                <>
                  <h2 className="text-s font-bold leading-6 text-gray-600 mb-3">
                    Pilih Metode Pembayaran
                  </h2>
                  <div className={"grid-cols-4 grid gap-1"}>
                    {PaymentMethodStore.list.map((item, idx) => {
                      const active = item.name === currentOrder.payment_method;
                      return (
                        <div
                          key={idx}
                          className="mb-2 mr-5 flex flex-1 flex-col"
                        >
                          <button
                            className={`opacity-75 hover:opacity-100 border-gray-400 border-2 rounded-lg overflow-hidden relative ${
                              (item.name === currentOrder.payment_method) && "border-blue-400 opacity-100"
                            }`}
                            onClick={() => {
                              runInAction(
                                () => (currentOrder.payment_method = item.name)
                              );
                              
                            }}
                          >
                            {!!active && (
                              <>
                                <div className="absolute top-0 left-0 right-0 bottom-0 bg-blue-300 opacity-25" />
                                <Check
                                  size={35}
                                  className="absolute top-0 right-0 m-1 text-blue-600 bg-white rounded-full p-1 border border-blue-300"
                                />
                              </>
                            )}

                            <div
                              className={`h-20 w-full flex-none overflow-hidden bg-contain bg-center bg-no-repeat`}
                              style={{
                                backgroundImage: `url(${
                                  ConfigStore.server_url +
                                  "/" +
                                  item.img_url.replace(/ /g, "%20")
                                })`,
                              }}
                            />
                          </button>
                          <div className="text-sm text-center">{item.name}</div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>

            <div className="flex flex-col border border-gray-300 rounded-md p-3">
              <div className="flex flex-1 flex-col">
                <h2 className="text-xs mb-1 text-gray-700">Keterangan :</h2>
                <TextAreaInput
                  className="mx-0 my-0 px-2 py-1 text-sm border-solid border rounded text-gray-800 border-gray-400 bg-gray-100 "
                  value={currentOrder.remarks}
                  onChange={(e) => {
                    runInAction(() => (currentOrder.remarks = e.target.value));
                  }}
                  rows={4}
                />
              </div>

              <div className="flex-end flex flex-col justify-items-end">
                <h2 className="text-xs mb-1 text-gray-700  md:font-bold">
                  Total Pembayaran :
                </h2>
                <div className="bg-gray-100 rounded border border-gray-400 col-span-6 flex justify-between flex-row flex-1 p-2 my-2">
                  {/* <h1 className="font-bold text-xl mr-2">Total</h1> */}
                  <h1 className="font-bold text-xl">
                    {moneyFormat(currentOrder.total, "Rp. ")}
                  </h1>
                </div>


                {currentOrder.payment_status === "NOW" && (
                <>

              <CheckBox 
                  label="Diskon"
                  checked={currentOrder?.isDiscount}
                  onChange={(e) => {
                    runInAction(() => {
                      currentOrder.isDiscount = !currentOrder.isDiscount;
                    });
                  }}
                  
                />
               
                  {currentOrder.isDiscount && (
                    <table className="mb-3">
                  <tr>
                    <td className="mr-1">
                      <h2 className="text-xs mb-1 mt-2 text-gray-700 mr-1">Diskon Persen :</h2>
                    </td>
                    <td className="">
                      <h2 className="text-xs mb-1 mt-2 text-gray-700 ml-1">Diskon Harga :</h2>
                    </td>
                  </tr>
                  <tr>
                  <td className="mr-1">
                    <div>
                      <TextCurrencyInput
                          placeholder="Please enter a number"
                          
                          value={currentOrder.discount>0?currentOrder.discount:""}
                          onValueChange={(value:any, name:any) => {
                            currentOrder.discount = parseInt(!!value?.toString()?value.toString():"0")
                            currentOrder.setDiscount()
                          }}
                          type="percent"
                          max={100}
                          style={{
                            width:70,
                            marginRight: "0.25rem"
                          }}  
                          autoFocus={currentOrder.isDiscount}
                          onFocus={(event:any) => {meta.focusedDiscount=true}}
                          onBlur={(event:any) => {meta.focusedDiscount=false}}
                        />
                        </div>
                    </td>
                    <td className="ml-1">
                      <div className="bg-gray-100 rounded border border-gray-400 p-1 ml-1"
                      style={{
                        width:"100%"
                      }}>
                          <TextCurrencyInput
                          placeholder="Please enter a number"
                          //defaultValue={currentOrder.amount_discount}
                          value={currentOrder.amount_discount>0?currentOrder.amount_discount:""}
                          onValueChange={(value:any, name:any) => {
                            currentOrder.amount_discount = parseInt(!!value?.toString()?value.toString():"0")
                            currentOrder.setDiscountAmount()
                          }}
                          type="amount"
                          style={{
                            width:70,
                            marginRight: "0.25rem"
                          }}  
                          
                          onFocus={(event:any) => {meta.focusedDiscountAmt=true}}
                          onBlur={(event:any) => {meta.focusedDiscountAmt=false}}
                        />
                        </div>
                    </td>
                  </tr>
                  </table>
                  )}
                  {!ConfigStore.isWeb && (meta.focusedDiscount || meta.focusedDiscountAmt) &&(
                    <div
                      style={{
                        marginBottom:96
                      }}
                      />
                  )}


                <CheckBox  
                label="Uang Kembalian"
                checked={currentOrder?.isChange}
                onChange={(e) => {
                  runInAction(() => {
                    currentOrder.isChange = !currentOrder.isChange;
                  });
                }}/>
                {currentOrder.isChange && (
                  <table>
                <tr>
                  <td className="mr-1">
                    <h2 className="text-xs mb-1 mt-2 text-gray-700 mr-1">Nominal Uang:</h2>
                  </td>
                  <td className="">
                    <h2 className="text-xs mb-1 mt-2 text-gray-700 ml-1">Uang Kembalian:</h2>
                  </td>
                </tr>
                <tr>
                <td className="mr-1">
                  <div>
                    <TextCurrencyInput
                        placeholder="Please enter a number"
                        defaultValue={currentOrder.cash}
                        onValueChange={(value:any, name:any) => {
                          currentOrder.cash = parseInt(!!value?.toString()?value.toString():"0")
                          currentOrder.setChanges()
                        }}
                        
                        style={{
                          width:70,
                          marginRight: "0.25rem"
                        }}  
                      />
                      </div>
                  </td>
                  <td className="ml-1">
                    <div className="bg-gray-100 rounded border border-gray-400 p-1 ml-1"
                    style={{
                      width:"100%"
                    }}>
                        <h1 className="rounded-md shadow-sm  flex-grow-1 text-right w-full"
                        style={{
                          width:100
                        }} >
                          {moneyFormat(currentOrder.change, "Rp. ")}
                        </h1>
                      </div>
                  </td>
                </tr>
                </table>
                )}
                </>
                )}
                {!ConfigStore.isWeb && meta.focusedChange &&(
                  <div
                  style={{
                    marginBottom:96
                  }}
                  />
                )
                }

                <div className="col-span-6 bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
                  <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                      type="button"
                      className="inline-flex justify-center items-center w-full rounded-md border border-transparent px-6 py-2 bg-blue-600 text-xl leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple flex-3"
                      onClick={submit}
                      disabled={!currentOrder.canSubmit}
                    >
                      Simpan
                    </button>
                  </span>
                  <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                      type="button"
                      className="inline-flex justify-center items-center w-full rounded-md border border-gray-300 px-6 py-2 bg-white text-xl leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:leading-5 ripple flex-1"
                      onClick={dismiss}
                      style={{
                        borderWidth: 1,
                        borderColor: "gray",
                      }}
                    >
                      Batal
                    </button>
                  </span>
                </div>
              </div>
            </div>
            {/* <div className={"mb-2 flex flex-col"}>
              <h2 className="text-xs mb-1 text-gray-700">Keterangan :</h2>
              <TextAreaInput
                className="mx-0 my-0 px-2 py-1 text-sm border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                value={currentOrder.remarks}
                onChange={(e) => {
                  runInAction(() => (currentOrder.remarks = e.target.value));
                }}
                rows={4}
              />
            </div> */}
            {/* <div className="bg-gray-100 mb-2 p-2 rounded border border-gray-400 col-span-6"> */}
            {/* <div className="flex flex-row justify-between mb-1">
                  <h1 className=" text-sm">Subtotal</h1>
                  <h1 className=" text-sm">
                    ({currentOrder.itemsQty} Items){" "}
                    {moneyFormat(currentOrder.subtotal, "Rp. ")}
                  </h1>
                </div>
                <div className="flex flex-row justify-between mb-1">
                  <h1 className=" text-sm">Tax</h1>
                  <h1 className=" text-sm">
                    ({currentOrder.ppn}%){" "}
                    {moneyFormat(currentOrder.tax_amount, "Rp. ")}
                  </h1>
                </div>
                <div className="flex flex-row justify-between mb-1">
                  <h1 className=" text-sm">Discount</h1>
                  <h1 className=" text-sm">
                    ({currentOrder.discount}%){" "}
                    {moneyFormat(currentOrder.discount_amount, "Rp. ")}
                  </h1>
                </div> */}
            {/* <div className="flex flex-row justify-between">
                  <h1 className="font-bold text-xl">Total</h1>
                  <h1 className="font-bold text-xl">
                    {moneyFormat(currentOrder.total, "Rp. ")}
                  </h1>
                </div> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ModalSubmitNew;
