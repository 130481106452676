
import scannerTotem   from "@src/assets/images/scanner-totem.png";
import scannerCashier from "@src/assets/images/scanner-cashier.png";

import Modal from "@src/libs/ui/Modal";

import ConfigStore from "@src/model/config";

import { observer, useLocalObservable } from "mobx-react";
import React from "react";
import { runInAction } from "mobx";
import OrderStore from "@src/model/order-repo";
import useAsyncEffect from "@src/libs/utils/useAsyncEffect";
import { Device } from "@capacitor/core";
import { AppConfig } from "@src/config/app";
const PrintBT = require("thermal-printer-cordova-plugin/www/thermal-printer");


const ModalInfo: React.FC = observer(() => {
  
  const meta = useLocalObservable(() => ({
    visibleSugest: false,
    focus: "",
    isMobile: false,
    btIsOn:false,
    listPrinter: [],
  }));
  const cancel = () => {
    
    if(ConfigStore.showModalBarcode==10){
      ConfigStore.showModalBarcode=0
    }
    
  };
  

  useAsyncEffect(async () => {
    meta.btIsOn = false
    //const info = await Device.getInfo();
    
    //if (info.platform !== "web") {
      
      runInAction(() => (meta.isMobile = true));
      await PrintBT.listPrinters(
        { type: "bluetooth" },
        (res: any) => runInAction(() => {
          
          if(res.length>0){
            meta.btIsOn = true
          }
        }),
        (e: any) => console.log("list error: ", e)
      );
    //}
  }, [ConfigStore.showModalBarcode]);


  return (
    <Modal visible={ConfigStore.showModalBarcode==10} onDismiss={cancel}
    className="h-90 absolute">
      <div className="bg-white p-4 h-full rounded-md">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex flex-1 flex-col">

          <h1
              className="text-xl font-bold leading-6 text-gray-800 mb-1"
              id="modal-headline"
            >
              Informasi Akun
            </h1>
            <div className="mb-4">
              <div className="font-bold">
                  {ConfigStore.fullname}
              </div>
              <div className="italic">
                  {ConfigStore.role}
              </div>
            </div>
            <h1
              className="text-xl font-bold leading-6 text-gray-800 mb-1"
              id="modal-headline"
            >
              Perangkat Terhubung
            </h1>
            <div className="pb-3 mb-3">
              <tr className="pb-3 mb-3">
                  <td className="py-1">
                      Printer Struk
                  </td>
                  <td className="mx-2 px-2 py-1">
                    {" : "}
                  </td>
                  <td  className={`items-end text-right ${ConfigStore.selectedStrukName!="" && meta.btIsOn==true ?"":" italic "}`}>
                      <div className="flex flex-col">
                          <div>
                            {ConfigStore.selectedStrukName!=""  && meta.btIsOn==true ?ConfigStore.selectedStrukName:"Tidak Terhubung"}
                          </div>
                            {ConfigStore.selectedStrukPrinters!="" && meta.btIsOn==true &&
                            <div  className="text-sm">
                                {ConfigStore.selectedStrukPrinters!=""  && meta.btIsOn==true ?ConfigStore.selectedStrukPrinters:"Tidak Terhubung"}
                            </div>
                            }
                      </div>
                      
                  </td>
              </tr>
              <tr className="pb-3 mb-3">
                <td className="mb-2 pb-2">
                    {"    "}
                  </td>
              </tr>
              <tr  className="py-1 my-1">
                  <td>
                      Printer Label
                  </td>
                  <td className="mx-2 px-2">
                    {"  :  "}
                  </td>
                  <td  className={`items-end text-right ${ConfigStore.selectedLabelName!=""  && meta.btIsOn==true ?"":" italic "}`}>
                  <div className="flex flex-col">
                          <div>
                            {ConfigStore.selectedLabelName!=""  && meta.btIsOn==true ?ConfigStore.selectedLabelName:"Tidak Terhubung"}
                          </div>
                            {ConfigStore.selectedLabelPrinters!="" && meta.btIsOn==true &&
                            <div className="text-sm">
                                {ConfigStore.selectedLabelPrinters!=""  && meta.btIsOn==true ?ConfigStore.selectedLabelPrinters:"Tidak Terhubung"}
                            </div>
                            }
                      </div>
                  </td>
              </tr>
              <tr className="pb-3 mb-3">
                <td className="mb-2 pb-2">
                    {"    "}
                  </td>
              </tr>
              <tr className="py-1 my-1">
                  <td>
                      Totem
                  </td>
                  <td className="mx-2 px-2">
                      {" : "}
                  </td>
                  <td  className={`items-end text-right ${ConfigStore.socketIsConnected!==1?" italic ":ConfigStore.selectedTotem.name!==""?"":" italic "}`}>
                    {ConfigStore.socketIsConnected!==1?"Koneksi Gagal":ConfigStore.selectedTotem.name!==""?ConfigStore.selectedTotem.name:"Tidak Terhubung"}
                  </td>
              </tr>
              
            </div>
            <div className="flex-grow flex flex-1">

            </div>
            <div className="italic">
                  Kelava-pos v.{AppConfig.appVersion}
              </div>
          </div>
        </div>
      </div>
      
    </Modal>
  );
});



export default ModalInfo;
