import { moneyFormat } from "@src/libs/utils/stringFormat";
import OrderStore from "@src/model/order-repo";
import { OrderProductSingle } from "@src/model/order-single";
import { Trash } from "heroicons-react";
import { observer } from "mobx-react";
import React from "react";

interface IOrderItems {
  item: OrderProductSingle;
}

const OrderItems: React.FC<IOrderItems> = observer((props) => {
  const { item } = props;
  if (!item) return null;
  const deleteItem = () => {
    OrderStore.currentOrder.deleteOrderItem(item.id);
  };
  const update = () => {
    OrderStore.currentOrder.updateOrderProduct(item.id);
  };

  return (
    <div
      className="mb-1 mx-2 py-2 border-b border-gray-300 flex flex-row xl:flex-row button"
      onClick={update}
    >
      <div
        className={`h-12 w-12 flex-none overflow-hidden bg-cover bg-center rounded-lg`}
        style={{ backgroundImage: `url("${item.product?.image}")` }}
      />
      <div className="px-2 w-auto flex-1">
        <h1
          className={
            "text-md font-bold text-gray-800 overflow-hidden truncate max-h-3"
          }
        >
          {item.product?.product_name}
        </h1>
        <p className="text-gray-800 text-sm">{`${item.complement_str}`}</p>
        <p className="text-gray-800 text-xs">{`${
          item.complement_ttl !== 0
            ? moneyFormat(item.complement_ttl, "Rp. ")
            : ""
        }`}</p>
        <h4 className="text-gray-800 text-sm">
          x{item.qty} @ {moneyFormat(item.subtotal, "Rp. ")}
        </h4>
      </div>
      <button
        className="w-10 h-10 ml-3 bg-red-100 hover:bg-red-200 rounded-md flex justify-center items-center  hover:shadow-lg ripple"
        onClick={deleteItem}
      >
        <Trash size={20} className="text-red-600" />
      </button>
    </div>
  );
});

export default OrderItems;
