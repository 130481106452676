import { Device } from "@capacitor/core";
import { dateFormat, moneyFormat } from "@src/libs/utils/stringFormat";
import ConfigStore from "@src/model/config";
import OrderStore from "@src/model/order-repo";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import DataIsEmpty from "../DataIsEmpty";


const ReactPullToRefresh = require('react-pull-to-refresh');


export default observer(() => {

  // let isWeb=false

  // Device.getInfo().then((it)=>{
  //   isWeb = (it.platform === "web")
  // })

  if (OrderStore.getList.length === 0) {
    return (
      <div className="col-span-4 lg:col-span-3 overflow-auto overflow-x-hidden bg-white h-full">
        <DataIsEmpty
          description={"Tidak ada data."}
          onRefresh={() => OrderStore.load()}
        />
      </div>
    );
  }

  return (

  
    <div className="col-span-4 lg:col-span-3 overflow-auto overflow-x-hidden bg-white h-full">
      
        
      {/* <ReactPullToRefresh 
        onRefresh={() => OrderStore.load()}
        
      > */}
        <ul
        style={{
          paddingBottom:"96px"
        }}>         
        {/* <div className="col-span-4 lg:col-span-3 bg-white h-full"> */}
          <div className="divide-y border-gray-300 overflow-scroll h-full">
          
            {OrderStore.getList.map((item, key) => {
              return (
                <div
                  key={key}
                  className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                    item.id === OrderStore.idDetailOrder ?
                    "bg-blue-100 hover:bg-blue-100 border-l-4 border-blue-700":"bg-white"
                  }`}
                  onClick={() =>
                    runInAction(() => (OrderStore.idDetailOrder = item.id))
                  }
                >
                  <div className="text-gray-800 font-bold text-base lg:text-sm">
                    {item.additional_customer_name}
                  </div>
                  <div className="text-gray-800 font-bold text-base lg:text-sm">
                    #{item.sales_order_number} - {item.src}
                  </div>
                  {!!item.phone && item.phone!=="null" && (
                    <div className="text-gray-600 text-base lg:text-sm">
                      Telp. {item.phone}
                    </div>
                  )}
                  <div className="text-gray-600 text-base lg:text-sm overflow-hidden truncate flex-row flex">
                    {dateFormat(item.sales_order_date, "EEE, d MMM")} -{" "}
                    <span className="capitalize flex flex-grow">{item.so_status}</span>

                    {item.queue!==""&&item.queue!=="null" &&(
                      <span style={{
                        justifyContent:'flex-end',
                        fontWeight: 'bold',
                        fontSize:24,
                        color:item.q_status==0?"#339949":item.q_status==1?"#479DEB":"#CCCCCC"
                        }}>{item.queue}</span>
                    )}
                    
                  </div>
                  </div>
                
              );
            })}
           
          </div>
        </ul>
        {/* </div> */}
        {/* </ReactPullToRefresh> */}
        {/* //ConfigStore.isWeb=isWeb */}
        <div 
        className={`col-span-4 lg:col-span-3 flex flex-col py-4 px-5 h-24 absolute bottom-0 ${ConfigStore.isWeb?"w-1/4":"w-1/3"} `}
        style={{
          backgroundColor:"#EDF8FF",
          borderTop:"15px",
          borderColor:"#5A98DB",
          position:"absolute",
          bottom:0,
        }}
        >

          Summary Penjualan:
          
          
          <span style={{
                        justifyContent:'flex-end',
                        fontWeight: 'bold',
                        fontSize:24,
                        textAlign:"end"
                        }}>
                          {moneyFormat(OrderStore.getSummary, "Rp. ")}
                        </span>
          
        </div>
        
  </div>
   
    
  );
});

                    

                    {/* <Icon
                      icon={ic_hourglass_full}
                      size={16}
                      style={{
                        paddingRight: 4,
                        backgroundSize: 16,
                        color: "#024f94ff",
                      }}
                    /> */}