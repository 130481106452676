import Modal from "@src/libs/ui/Modal";
import ConfigStore, { Totem } from "@src/model/config";
import { connectTotem, getTotem, initTotem, leftRoom } from "@src/services/Totem";

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Icon from "react-icons-kit";
import Item from "../queue/Item";
import {sphere} from 'react-icons-kit/icomoon/sphere'



const ModalTotemList = observer(() => {
  let shown =ConfigStore.showListTotem

  useEffect(() => {
    if(ConfigStore.socketIsConnected!==1 && ConfigStore.showListTotem===true){
      initTotem()
    }
  }, [ConfigStore.showListTotem]);

  if (!shown) return null;
  const submit = () => {
    if(ConfigStore.tempRoom==""){
      alert("Pilih Totem terlebih dahulu")
      console.log("Pilih Totem terlebih dahulu")
    }else{
      
      ConfigStore.selectedTotem = ConfigStore.tempTotem
      connectTotem();
      ConfigStore.showListTotem=false

      dismiss()
    }
    
  };


  const disconnect = () => {
    ConfigStore.tempRoom=""
    ConfigStore.selectedTotem = new Totem
    ConfigStore.showListTotem=false
    leftRoom();
    dismiss()
  };

  const dismiss = () => {
    ConfigStore.showListTotem = false;
    shown = false;
  };

  return (
    <Modal visible={ConfigStore.showListTotem} onDismiss={dismiss}>
      <div className="bg-white px-4 pt-4">
      <div className="bg-white flex-row w-full mb-1">
        <span className="flex w-full rounded-md shadow-sm sm:w-auto">
          <text className="font-bold text-xl flex-1">
            Pilih Totem
          </text>
          <Icon
          icon={sphere}
          size={24}
          style={{
            paddingRight: 10,
            backgroundSize: 30,
            color: ConfigStore.socketIsConnected===1?"#38a169":ConfigStore.socketIsConnected===-1?"#e53e3e":"#4a5568",
          }}
        />
        </span>
        
        
        
      </div>

        {ConfigStore.socketIsConnected===1 ? ConfigStore.totems.map((item: Totem, index: any) => (
          <RenderItemLabel item={item} index={index} />
        )) : <div/>}

        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              className="justify-center w-full rounded-md border border-transparent px-8 py-2 bg-green-600 text-xl leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple"
              onClick={submit}
              // disabled={tempOrderProduct.qty ==== 0}
            >
              Sambungkan
            </button>
          </span>
          <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              className="justify-center w-full rounded-md border border-transparent px-8 py-2 bg-red-600 text-xl leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-green-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple"
              onClick={disconnect}
            >
              Tutup
            </button>
          </span>
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-xl leading-6 font-medium text-gray-700 shadow-sm hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:leading-5 ripple"
              onClick={dismiss}
            >
              Batal
            </button>
          </span>
        </div>
      </div>
    </Modal>
  );
});

const RenderItemLabel = observer((props: any) => {
  const { item, index } = props;
  const onClickListener = (x: any) => {
    ConfigStore.updateChecked(x)
    //ConfigStore.selectedTotem=ConfigStore.totems[x]
    // console.log(ConfigStore.selectedTotem)
  };
  return (
    <tr key={index} className="bg-white hover:bg-gray-100">
      <button
        key={index}
        className={`${
          ConfigStore.totems[index].room+ConfigStore.totems[index].name === ConfigStore.tempRoom ? "bg-blue-600" : "bg-white"
        }`}
        style={{
          borderRadius: 10,
          borderWidth: 2,
          borderColor:
          ConfigStore.totems[index].room+ConfigStore.totems[index].name === ConfigStore.tempRoom ? "#1ACBDA" : "#fff",
          backgroundColor:
          ConfigStore.totems[index].room+ConfigStore.totems[index].name === ConfigStore.tempRoom ? "#1ACBDA33" : "#fff",
          flexGrow: 1,
          width: "100%",
        }}
        onClick={() => onClickListener(index)}
      >
        <td className="text-center px-2 py-2 w-16">{index + 1}. </td>
        <td className="text-left px-2 py-2">
          <div>
            <div className="font-bold">{item.name}</div>
            <div className="text-sm">{item.type}</div>
          </div>
        </td>
      </button>
    </tr>
  );
});

export default ModalTotemList;
