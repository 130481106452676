import { RouteComponentProps } from "@reach/router";
import Page from "@src/libs/ui/Page";
import OrderStore from "@src/model/order-repo";
import UILoading from "@src/ui/Loading";
import ProductPicker from "@src/ui/order/ProductPicker";
import CurrentOrder from "@src/ui/orderlist/CurrentOrder";
import Detail from "@src/ui/orderlist/Detail";

import List from "@src/ui/orderlist/List";
import TopBar from "@src/ui/TopBar";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import React, { Suspense, useEffect } from "react";
import ModalCancel from "@src/ui/orderlist/ModalCancel";
import ProductStore from "@src/model/product-repo";
import ConfigStore from "@src/model/config";
import ModalLabelItem from "@src/services/ModalLabelList";
import ModalSubmitNew from "@src/ui/orderlist/ModalSubmitNew";
import ModalSubmit from "@src/ui/orderlist/ModalSubmit";
import PaymentMethodStore from "@src/model/paymentmethod-repo";
import ModalTotemList from "@src/ui/totem/ModalTotemList";
import ModalScanTotem from "@src/ui/scanner/ModalScanTotem";
import ModalScanCashier from "@src/ui/scanner/ModalScanCashier";


import { Plugins, KeyboardInfo } from "@capacitor/core";
import PromoStore from "@src/model/promo-repo";

const { Device } = Plugins;


const OrderList: React.FC<RouteComponentProps> = observer((props) => {


  useEffect(() => {
    if(!ConfigStore.isWeb){
      ConfigStore.checkConfigStore()
    }
    ProductStore.reload();
    PromoStore.reload();
    PaymentMethodStore.reload();
    OrderStore.load();
    let interval = setInterval(() => {
      OrderStore.load();
    }, 10000);

    if (!!OrderStore.idDetailOrder) {
      runInAction(() => {
        OrderStore.idDetailOrder = 0;
      });
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  

  return (
    <Suspense fallback={<UILoading />}>
      <Page {...props} className="h-full w-screen">
        
      
        {{
          default: (
            <div className={`h-full w-full overflow-hidden flex flex-col`}>
              <TopBar className="flex"  totem={ConfigStore.selectedTotem}/>
              {!!OrderStore.openProductPicker ? (
                <div className={`h-full grid grid-cols-12 overflow-hidden`}>
                  <ProductPicker currentOrder={OrderStore.updateOrder} />
                  <CurrentOrder />
                </div>
              ) : (
                <div
                  className={`grid grid-cols-12 h-full w-full overflow-hidden ${
                    ConfigStore.statusCamera === "hidden"
                      ? "bg-gray-100"
                      : "hidden"
                  }`}
                >
                  <List />
                  <Detail />
                  <ModalSubmitNew />
                  {/* <ModalSubmit /> */}
                </div>
              )}
              <ModalCancel />
            </div>
          ),
        }}
      </Page>
      
      <ModalLabelItem/>
      <ModalTotemList/>
      <ModalScanTotem/>
      <ModalScanCashier/>
    </Suspense>
  );
});

export default OrderList;
