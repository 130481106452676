import AppVersionAPI from "@src/api/AppVersionApi";
import { AppConfig } from "@src/config/app";
import { Model } from "@src/libs/model/model";
import { AppVersionSingle } from "./appversion-single";

import { Plugins, AppState, Browser } from "@capacitor/core";
const { App } = Plugins


export class AppVersionRepository extends Model {
    appVersion: AppVersionSingle = AppVersionSingle.childOf(this);
    lastChecked?:Date

    
    initAppVersion() {
        this.appVersion._loadJSON(new AppVersionSingle()._json);
        if(!this.lastChecked){
            let x = new Date()
            x.setDate(1)
            x.setMonth(8)
            x.setFullYear(2021)
            x.setFullYear(2021)
            x.setFullYear(2021)
            x.setFullYear(2021)

            x.setHours(23)
            x.setMinutes(59)
            x.setSeconds(59)

            
            this.lastChecked=x
            // alert("x: "+this.lastChecked);
            // alert(this.lastChecked);
        }
      }
    
    public async checkAppVersion() {
        let now=new Date()
        //alert(now+" > "+this.lastChecked!!+"->"+(now > this.lastChecked!!));
        if(now > this.lastChecked!!){
            
        }else{
            return
        }


      await AppVersionAPI.checkAppVersion().then((res: any) =>{
        this._loadJSON({
            appVersion: res,
        })
        
        if(this.appVersion.version_code > AppConfig.appVersionCode){
            if(this.appVersion.mandatory==="N"){
                let conf = window.confirm("Update aplikasi anda dan nikmati performa yang lebih stabil");
                if(conf){
                    this.toPlayStore()
                }
            }else{
                let conf = window.confirm("Update aplikasi anda dan nikmati performa yang lebih stabil");
                if(conf){
                    this.toPlayStore()
                }
                
            }
            this.lastChecked=now
            this.lastChecked.setHours(23)
            this.lastChecked.setMinutes(59)
            this.lastChecked.setSeconds(59)
        }

    });
    }

    public async toPlayStore() {
        await Browser.open({ url: 'https://play.google.com/store/apps/details?id=com.kelava.pos' });
    }
  }

  const AppVersionStore = AppVersionRepository.create({
    localStorage: false,
    storageName: "AppVersionRepository",
  });
  export default AppVersionStore;