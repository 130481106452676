import axios, { AxiosRequestConfig } from "axios";

export interface IAPI extends AxiosRequestConfig {
  onError?: (res: any) => void;
}

export default (e: IAPI) => {
  let url = e.url;
  const headers = {
    "content-type": "application/json",
    ...(e?.headers || {}),
  };
  let onError: any;
  if (e.onError) {
    onError = e.onError;
  }

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios({ ...e, url, headers });
      if (res.status >= 200 && res.status < 300) {
        if (!!res.data) resolve(res.data);
        else resolve(res);
      } else {
        let error = res;
        if (res.data) error = res.data;
        onError(error);
        resolve(error);
      }
    } catch (e) {
      if (onError) {
        let error = e.response;
        if (e.response && e.response.data) error = e.response.data;
        onError(error);
        resolve(error);
      } else {
        if (e.response && e.response.data) resolve(e.response.data);
        else resolve(e.response);
      }
    }
  });
};
