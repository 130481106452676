import TextInput from "@src/libs/ui/TextInput";
import OrderStore from "@src/model/order-repo";
import ProductStore from "@src/model/product-repo";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { useRef } from "react";




export default observer(() => {
  let PS: any = ProductStore;
  if (OrderStore.currentOrder.productMember.list.length > 0) {
    PS = OrderStore.currentOrder.productMember;
  }

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      if (PS.listProduct.length === 1) {
        OrderStore.setOrderProduct(PS.listProduct[0]);
        // OrderStore.updateOrder.updateOrderProduct(PS.listProduct[0].id);
        OrderStore.currentOrder.addOrderProduct(OrderStore.tempOrderProduct);
      }
    }
  };

  return (
    <div className="w-full justify-center flex mb-4">
      <div className="w-3/4">
        <TextInput
          className="w-full text-center border-solid border-gray-400 border rounded-md text-gray-800 h-10 text-lg"
          placeholder="Cari Produk"
          value={PS.filter}
          // autoFocus= {true}
          onChange={(e) => {
            runInAction(() => (PS.filter = e.target.value));
          }}
          
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
});
